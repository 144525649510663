var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"deliveryMethodsObserver"},[_c('b-form',{staticClass:"form-container",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-col',[_c('h4',{staticClass:"mb-2"},[_vm._v("Konfiguracja")]),_c('b-form-group',{attrs:{"label":"Język"}},[_c('validation-provider',{attrs:{"name":"Język","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"v-language-select","label":"value","placeholder":"Wybierz język","clearable":false,"deselectFromDropdown":false,"options":_vm.languages,"disabled":_vm.isCreateView},on:{"input":_vm.fetchByLocale},model:{value:(_vm.lang),callback:function ($$v) {_vm.lang=$$v},expression:"lang"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('h4',{staticClass:"mb-2 mt-3"},[_vm._v("Informacje o metodzie dostawy")]),_c('b-form-group',{attrs:{"label":"Nazwa"}},[_c('validation-provider',{attrs:{"name":"Nazwa","rules":"required|min:3|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Nazwa","disabled":!_vm.lang},model:{value:(_vm.form.company_name),callback:function ($$v) {_vm.$set(_vm.form, "company_name", $$v)},expression:"form.company_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"for":"v-logo","label":"Logo"}},[_c('b-button',{attrs:{"disabled":!_vm.lang},on:{"click":function($event){return _vm.toggleShowModal('company_logo')}}},[_vm._v(" Wybierz zdjęcie ")]),_c('validation-provider',{attrs:{"name":"Logo","rules":"required|size:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.files.company_logo)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.company_logo.file),expression:"files.company_logo.file"}],attrs:{"type":"hidden","name":"Logo"},domProps:{"value":(_vm.files.company_logo.file)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files.company_logo, "file", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.company_logo),expression:"files.company_logo"}],attrs:{"type":"hidden","name":"Logo"},domProps:{"value":(_vm.files.company_logo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files, "company_logo", $event.target.value)}}}),(_vm.showImagesModal.company_logo)?_c('ImageSelector',{attrs:{"files":_vm.filesArray,"show-modal":_vm.showImagesModal.company_logo},on:{"closeModal":function($event){return _vm.toggleShowModal('company_logo')},"submit":function (fileList) { return _vm.handleFile(fileList[0], 'company_logo'); }}}):_vm._e(),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.files.company_logo)?_c('ImagePreview',{attrs:{"image":_vm.files.company_logo},on:{"deleteImage":function () { return _vm.handleDelete('company_logo'); }}}):_vm._e()],1),_c('b-form-group',{attrs:{"label":"Dodatkowe informacje (wyświetlane w nawiasie)"}},[_c('validation-provider',{attrs:{"name":"Dodatkowe informacje","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Dodatkowe informacje (wyświetlane w nawiasie)","disabled":!_vm.lang},model:{value:(_vm.form.additional_info),callback:function ($$v) {_vm.$set(_vm.form, "additional_info", $$v)},expression:"form.additional_info"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label":""}},[_c('b-form-checkbox',{model:{value:(_vm.form.cash_on_delivery),callback:function ($$v) {_vm.$set(_vm.form, "cash_on_delivery", $$v)},expression:"form.cash_on_delivery"}},[_vm._v("Za pobraniem?")])],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label":""}},[_c('b-form-checkbox',{model:{value:(_vm.form.is_inpost_based),callback:function ($$v) {_vm.$set(_vm.form, "is_inpost_based", $$v)},expression:"form.is_inpost_based"}},[_vm._v(" Dostawa InPost? (pozwoli wyświetlić mapę z punktami odbioru) ")])],1),_c('b-form-group',{attrs:{"label":"Koszt (pozostaw 0 dla bezpłatnego modelu dostawy)"}},[_c('validation-provider',{attrs:{"name":"Koszt","rules":"required|positive_or_zero|decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","step":"0.01","min":"0","placeholder":"Koszt"},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label":""}},[_c('b-form-checkbox',{model:{value:(_vm.form.is_primary),callback:function ($$v) {_vm.$set(_vm.form, "is_primary", $$v)},expression:"form.is_primary"}},[_vm._v("Priorytetowa dostawa?")])],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label":""}},[_c('b-form-checkbox',{model:{value:(_vm.form.is_special_delivery),callback:function ($$v) {_vm.$set(_vm.form, "is_special_delivery", $$v)},expression:"form.is_special_delivery"}},[_vm._v("Specjalna dostawa?")])],1),(_vm.form.is_special_delivery)?_c('b-form-group',{attrs:{"label":"Kategorie"}},[_c('validation-provider',{attrs:{"name":"Kategorie","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"v-categories-select","label":"name","placeholder":"Wybierz kategorie","multiple":true,"clearable":false,"deselectFromDropdown":false,"closeOnSelect":false,"options":_vm.subcategories},model:{value:(_vm.form.categories),callback:function ($$v) {_vm.$set(_vm.form, "categories", $$v)},expression:"form.categories"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3347783679)})],1):_vm._e()],1),_c('b-col',{staticClass:"mt-5"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Zapisz ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }