<template>
  <validation-observer ref="deliveryMethodsObserver">
    <b-form @submit.prevent="validationForm" class="form-container">
      <b-col>
        <h4 class="mb-2">Konfiguracja</h4>

        <b-form-group label="Język">
          <validation-provider #default="{ errors }" name="Język" rules="required">
            <v-select
              id="v-language-select"
              label="value"
              placeholder="Wybierz język"
              v-model="lang"
              :clearable="false"
              :deselectFromDropdown="false"
              :options="languages"
              :disabled="isCreateView"
              @input="fetchByLocale" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col>
        <h4 class="mb-2 mt-3">Informacje o metodzie dostawy</h4>

        <b-form-group label="Nazwa">
          <validation-provider #default="{ errors }" name="Nazwa" rules="required|min:3|max:255">
            <b-form-input v-model="form.company_name" placeholder="Nazwa" :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group for="v-logo" label="Logo">
          <b-button @click="toggleShowModal('company_logo')" :disabled="!lang"> Wybierz zdjęcie </b-button>
          <validation-provider #default="{ errors }" name="Logo" rules="required|size:5000">
            <!-- workaround for validation-provider -->
            <input v-if="files.company_logo" type="hidden" name="Logo" v-model="files.company_logo.file" />
            <input v-else type="hidden" name="Logo" v-model="files.company_logo" />

            <ImageSelector
              v-if="showImagesModal.company_logo"
              :files="filesArray"
              :show-modal="showImagesModal.company_logo"
              @closeModal="toggleShowModal('company_logo')"
              @submit="(fileList) => handleFile(fileList[0], 'company_logo')" />
            <small class="text-danger d-block">{{ errors[0] }}</small>
          </validation-provider>

          <ImagePreview
            v-if="files.company_logo"
            :image="files.company_logo"
            @deleteImage="() => handleDelete('company_logo')" />
        </b-form-group>

        <b-form-group label="Dodatkowe informacje (wyświetlane w nawiasie)">
          <validation-provider #default="{ errors }" name="Dodatkowe informacje" rules="max:255">
            <b-form-input
              v-model="form.additional_info"
              placeholder="Dodatkowe informacje (wyświetlane w nawiasie)"
              :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group class="mt-2" label="">
          <b-form-checkbox v-model="form.cash_on_delivery">Za pobraniem?</b-form-checkbox>
        </b-form-group>

        <b-form-group class="mt-2" label="">
          <b-form-checkbox v-model="form.is_inpost_based">
            Dostawa InPost? (pozwoli wyświetlić mapę z punktami odbioru)
          </b-form-checkbox>
        </b-form-group>

        <b-form-group label="Koszt (pozostaw 0 dla bezpłatnego modelu dostawy)">
          <validation-provider #default="{ errors }" name="Koszt" rules="required|positive_or_zero|decimal:2">
            <b-form-input v-model="form.price" type="number" step="0.01" min="0" placeholder="Koszt" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group class="mt-2" label="">
          <b-form-checkbox v-model="form.is_primary">Priorytetowa dostawa?</b-form-checkbox>
        </b-form-group>

        <b-form-group class="mt-2" label="">
          <b-form-checkbox v-model="form.is_special_delivery">Specjalna dostawa?</b-form-checkbox>
        </b-form-group>

        <b-form-group v-if="form.is_special_delivery" label="Kategorie">
          <validation-provider #default="{ errors }" name="Kategorie" rules="required">
            <v-select
              id="v-categories-select"
              label="name"
              placeholder="Wybierz kategorie"
              v-model="form.categories"
              :multiple="true"
              :clearable="false"
              :deselectFromDropdown="false"
              :closeOnSelect="false"
              :options="subcategories" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col class="mt-5">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary"> Zapisz </b-button>
      </b-col>
    </b-form>
  </validation-observer>
</template>

<script>
import FileUploadMixin from '@/mixins/FileUploadMixin.vue';
import NotificationMixin from '@/mixins/NotificationMixin.vue';
import { getLanguages } from '@/constants/languages';

import ImagePreview from '@/components/shared/ImagePreview.vue';
import ImageSelector from '@/components/shared/ImageSelector.vue';

export default {
  name: 'DeliveryMethodsFormComponent',
  mixins: [FileUploadMixin, NotificationMixin],
  props: {
    subcategories: {
      type: Array,
      default: () => [],
    },
    deliveryMethod: {
      type: Object,
      default: () => {},
    },
    isCreateView: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ImagePreview,
    ImageSelector,
  },

  data() {
    return {
      lang: {
        lang: 'pl',
        value: 'Polski',
      },
      languages: getLanguages(),

      form: this.deliveryMethod,
    };
  },

  created() {
    this.setFileUploadData(['company_logo'], ['company_logo']);
  },

  watch: {
    async deliveryMethod() {
      await this.setImage(this.deliveryMethod.company_logo, 'company_logo');

      const categories = this.subcategories.filter((category) => {
        return this.deliveryMethod.categories.find((cat) => {
          return cat === category.id;
        });
      });

      this.form = {
        ...this.deliveryMethod,
        company_logo: this.files.company_logo.file,
        categories: categories,
      };
    },
  },

  methods: {
    fetchByLocale() {
      this.$emit('fetchByLocale', this.lang.lang);
    },
    onSubmit() {
      const data = {
        ...this.form,
        lang: this.lang.lang,
        company_logo: this.files.company_logo,
      };

      this.$emit('onSubmit', data);
    },
    validationForm() {
      this.$refs.deliveryMethodsObserver.validate().then((success) => {
        if (success) {
          return this.onSubmit();
        }

        this.showFormValidationNotification();
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
