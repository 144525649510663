<template>
  <b-card>
    <form-component
      :subcategories="subcategories"
      :delivery-method="deliveryMethod"
      @onSubmit="onSubmit"
      :preview="preview"
      @fetchByLocale="fetchDeliveryMethod" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/delivery-methods/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { show, update, subcategories } from '@/http/delivery-methods';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      subcategories: [],
      deliveryMethod: {
        company_name: '',
        additional_info: '',
        price: 0,
        company_logo: null,
        cash_on_delivery: false,
        is_inpost_based: false,
        is_special_delivery: false,
        is_primary: false,
      },
      preview: '',
    };
  },

  async created() {
    subcategories().then(async (response) => {
      this.subcategories = response.data.data;
      await this.fetchDeliveryMethod();
    });
  },

  methods: {
    async fetchDeliveryMethod(lang = 'pl') {
      try {
        const { data } = await show(this.$route.params.id, { lang });

        this.deliveryMethod = data.data;
      } catch (error) {
        this.showErrorNotification(
          'Problem z pobraniem danych',
          'Wystąpił błąd podczas pobierania metody dostawy. Skontaktuj się ze swoim developerem.',
        );
      }
    },

    async onSubmit(form) {
      const formData = new FormData();
      formData.append('id', form.id);
      formData.append('company_name', form.company_name);
      formData.append('additional_info', form.additional_info);
      formData.append('price', form.price);
      formData.append('company_logo', form.company_logo.file, form.company_logo.fileName);
      formData.append('lang', form.lang);
      formData.append('cash_on_delivery', form.cash_on_delivery ? 1 : 0);
      formData.append('is_inpost_based', form.is_inpost_based ? 1 : 0);
      formData.append('is_special_delivery', form.is_special_delivery ? 1 : 0);
      formData.append('is_primary', form.is_primary ? 1 : 0);

      if (form.is_special_delivery) {
        for (const category of form.categories) {
          formData.append('categories[]', category.id);
        }
      }

      try {
        await update(form.id, formData);

        this.showSuccessNotification('Dane zapisane', 'Metoda dostawy została pomyślnie zaktualizowana.');
        this.$router.push({ name: 'delivery-methods-index' });
      } catch (error) {
        const message =
          error.response.data.message ??
          'Wystąpił błąd podczas aktualizacji metody dostawy. Skontaktuj się ze swoim developerem.';
        this.showErrorNotification('Problem z zapisaniem danych', message);
      }
    },
  },
};
</script>
