import axios from '../axios';

export const all = (params = {}) => {
  return axios.get('/delivery-methods', { params });
};

export const subcategories = (params = { lang: 'pl' }) => {
  return axios.get('/delivery-methods/data/subcategories', { params });
};

export const show = (id, params = {}) => {
  return axios.get(`/delivery-methods/${id}`, { params });
};

export const create = (payload) => {
  return axios.post('/delivery-methods', payload);
};

export const update = (id, payload) => {
  return axios.post(`/delivery-methods/${id}/update`, payload);
};

export const destroy = (id) => {
  return axios.delete(`/delivery-methods/${id}`);
};
